import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './PhoneCheck.css';
import Select from 'react-select';
import { Portal } from 'react-portal';
import { FormattedMessage } from 'react-intl';
import { checkKashtagAvailability } from '../../Providers/KashProvider';
import {
  signUp,
  authenticateUser,
  resendConfirmationCode,
} from '../../Providers/CognitoProvider';
import ReactLoading from 'react-loading';

const CODES = require('../../Data/CountryCodes'); // All the available country codes that the app handles

const PNF = require('google-libphonenumber').PhoneNumberFormat; // To Format the phone number entered according to the country
const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance(); // Instance of the libray to check phone number format and validity

const phoneMaxLength = 17; // Max length of the phone number that can be entered
const phoneMinLength = 8; // Min length of the phone number that can be entered

// select style
const customSelectStyles = {
  option: (base, state) => ({
    ...base,
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    fontFamily: 'm-r',
    color: '#828c8c',
    ':hover': {
      backgroundColor: '#bdbdbd',
      color: 'white',
    },
    backgroundColor: 'rgb(248, 248, 248)',
    fontSize: '18px',
  }),
  control: () => ({
    width: '100%',
    height: '100%',
    fontFamily: 'm-r',
    color: '#828c8c',
    fontSize: '18px',
    backgroundColor: 'white',
  }),
  valueContainer: () => ({
    color: '#828c8c',
    width: '80%',
  }),
  singleValue: (base, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...base, opacity, transition };
  },
};

class PhoneCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: {
        value: '+506',
        label: 'Costa Rica (+506)',
        country: 'Costa Rica',
      }, // the country code selected by the user, it's default value is CR
      message: '', // a message to show to the user
      validPhoneNumber: '', // final valid hpone number
      tempPhoneNumber: '', // temporary typed phone number
      isValidNumber: false, // validates if the entered phone number is valid
      verifying: false, // to see if the app is waiting for cognito to answere
    };
  }

  /**
   * Checks if the phone number is valid, with google-libphonenumber
   * @param {string} phoneNumber number to be checked
   * @param {string} countryCode example: +506
   */
  checkPhoneFormat(phoneNumber, countryCode) {
    if (
      phoneNumber.length >= phoneMinLength &&
      countryCode !== null &&
      phoneNumber.length <= phoneMaxLength
    ) {
      let isValid, type, formattedNumber;
      formattedNumber = phoneUtil.parse(countryCode + phoneNumber, ''); //second param is a 2-letter country code, all country codes can be found here -> http://www.worldatlas.com/aatlas/ctycodes.htm
      if (
        Object.keys(formattedNumber).length === 0 &&
        formattedNumber.constructor === Object
      ) {
        isValid = false;
      } else {
        isValid = phoneUtil.isValidNumber(formattedNumber);
        type = phoneUtil.getNumberType(formattedNumber); //| -1 = error | 0 = fixed-line | 1 = mobile | 2 = fixed-line-or-mobile
      }
      if (!isValid) {
        this.setState({
          message: this.setTransMessage(
            'PhoneCheck.MustBeValidNum',
            'Please enter a valid number'
          ),
          isValidNumber: false,
        });
      } else if (type === 1 || type === 2) {
        // 1 (mobile) or 2 (fixed-line-or-mobile)
        this.setState({ message: '', isValidNumber: true });
      } else {
        this.setState({
          message: this.setTransMessage(
            'PhoneCheck.MustBeCellNum',
            'Please enter a cell phone number'
          ),
          isValidNumber: false,
        });
      }
      return isValid;
    } else if (phoneNumber.length > phoneMaxLength) {
      this.setState({
        message: this.setTransMessage(
          'PhoneCheck.MustBeValidNum',
          'Please enter a valid number'
        ),
        isValidNumber: false,
      });
    } else if (countryCode === null) {
      this.setState({
        message: this.setTransMessage(
          'PhoneCheck.MustSelectCountry',
          'Please select a country'
        ),
        isValidNumber: false,
      });
    } else {
      this.setState({ message: '', isValidNumber: false });
    }
    return false;
  }

  /**
   * It's called every time that the phone number input changes. It
   * checks the format of the phone number, and if it is valid then
   * the validPhoneNumber state is set.
   * @param {object} event
   */
  onChangeNumber(event) {
    this.setState({ tempPhoneNumber: event.target.value });
    if (
      this.checkPhoneFormat(
        event.target.value,
        this.state.selectedCountry.value
      )
    ) {
      this.setState({
        validPhoneNumber:
          this.state.selectedCountry.value + '' + event.target.value,
      });
    }
  }

  /**
   * It's called every time the user changes the country code
   * in the select. It checks the format of the phone number, and
   * if it is valid then the validPhoneNumber state is set.
   * @param {object} selectedCountry
   */
  handleChange = (selectedCountry) => {
    this.setState({ selectedCountry });
    if (
      this.checkPhoneFormat(this.state.tempPhoneNumber, selectedCountry.value)
    ) {
      this.setState({
        validPhoneNumber:
          selectedCountry.value + '' + this.state.tempPhoneNumber,
      });
    }
  };

  /**
   * Communicates with the cognito user pool to make a sign up
   * of the user, if the user doesn't exist, the sign up is
   * processed and the verification code is sent to the user.
   * If the user already exists, then he/she is informed that
   * the entered phone number already exists.
   * @param {object} event
   */
  verifyPhoneNumber(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ verifying: true });
    checkKashtagAvailability(this.props.kashtag)
      .then((response) => {
        if (this.state.isValidNumber && response.available) {
          authenticateUser(this.state.validPhoneNumber, {
            onSuccess: (result) => {
              // shouldn't enter here, ever
              let message = (
                <FormattedMessage
                  id="App.Error"
                  defaultMessage="Oops!! We have registered a problem, but we'll solve it so it won't happen again"
                />
              );
              this.setState({ verifying: false, tempPhoneNumber: '', message });
            },
            onFailure: (error) => {
              switch (error.code) {
                case 'UserNotFoundException':
                  let validationDataList = [];
                  let validationData = {
                    Name: 'kashtag',
                    Value: this.props.kashtag,
                  };
                  validationDataList.push(validationData);
                  signUp(
                    this.state.validPhoneNumber,
                    validationDataList,
                    (err, result) => {
                      if (err) {
                        this.props.verificationCodeSent(false);
                        this.setState({ verifying: true });
                        switch (err.name) {
                          case 'UserLambdaValidationException':
                            this.setState({
                              message: this.setTransMessage(
                                'App.Error',
                                "Oops!! We have registered a problem, but we'll solve it so it won't happen again"
                              ),
                              isValidNumber: false,
                            });
                            break;
                          default:
                            this.setState({ isValidNumber: false });
                            break;
                        }
                      } else {
                        this.setState({ message: '' });
                        this.props.verificationCodeSent(true);
                        sessionStorage.setItem('confirmed_user', 'false');
                      }
                      this.setState({ verifying: false });
                    }
                  );
                  break;
                case 'UserNotConfirmedException':
                  resendConfirmationCode(
                    this.state.validPhoneNumber,
                    (err, result) => {
                      if (err) {
                        switch (err.name) {
                          case 'LimitExceededException':
                            this.setState({
                              message: this.setTransMessage(
                                'PhoneCheck.LimitExceededException',
                                'Attempt limit exceeded, please try after some time'
                              ),
                              isValidNumber: false,
                            });
                            break;
                          default:
                            this.setState({ isValidNumber: false });
                            break;
                        }
                      } else {
                        this.setState({ message: '', verifying: false });
                        this.props.verificationCodeSent(true);
                        sessionStorage.setItem('confirmed_user', 'false');
                      }
                    }
                  );
                  break;
                default:
                  this.setState({ isValidNumber: false, verifying: false });
                  break;
              }
            },
            mfaRequired: (result) => {
              sessionStorage.setItem('confirmed_user', 'true');
              this.setState({ message: '', verifying: false });
              this.props.verificationCodeSent(true);
            },
          });
        } else if (!response.available) {
          let message = (
            <FormattedMessage
              id="PhoneCheck.KashtagTaken"
              defaultMessage="You are not gonna believe it, someone else saved {kashtag} just a momento ago."
              values={{ kashtag: this.props.kashtag }}
            />
          );
          this.setState({ message, verifying: false });
        }
      })
      .catch((error) => {
        this.setState({ isValidNumber: false, verifying: false });
      });
  }

  /**
   * Sets FormattedMEssages to show the translations
   * @param {string} id translations id
   * @param {string} defaultMessage default message to show
   */
  setTransMessage(id, defaultMessage) {
    return <FormattedMessage id={id} defaultMessage={defaultMessage} />;
  }

  /**
   * Enables the section to type the phone number.
   */
  enableEdit() {
    this.setState({ message: '', isValidNumber: false });
    this.props.verificationCodeSent(false);
    if (this.props.isVerified) {
      this.props.setIsVerified(false);
    }
  }

  /**
   * It's called when enter is pressed when typing the phone number.
   * Checks if the phone number is valid and if it is, it sends
   * the phone number to be verified.
   * @param {object} event
   */
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      this.verifyPhoneNumber();
    }
  }

  /**
   * This returns the sections that lets the user type
   * the phone number to verify.
   */
  mainContent() {
    let options = CODES['codes'];
    return (
      <div>
        <h1>
          <FormattedMessage
            id="PhoneCheck.Title"
            defaultMessage="Link your phone number"
          />
        </h1>
        <form>
          <div className="all-form">
            <div className="phone input">
              <label>
                <FormattedMessage
                  id="PhoneCheck.Country"
                  defaultMessage="Country"
                />
              </label>
              <Select
                className="select"
                name="country-code"
                value={this.state.selectedCountry}
                onChange={this.handleChange}
                styles={customSelectStyles}
                menuShouldBlockScroll={false}
                isSearchable={false}
                options={options}
                maxMenuHeight={200}
                backspaceRemovesValue={false}
              />
            </div>
            <div
              className={
                this.state.message ? 'phone input phone-error' : 'phone input'
              }
            >
              <label
                onClick={() => {
                  this.phone.focus();
                }}
              >
                <FormattedMessage
                  id="PhoneCheck.CellPhone"
                  defaultMessage="Phone"
                />
              </label>
              <input
                ref={(ip) => (this.phone = ip)}
                type="number"
                autoFocus={true}
                onKeyPress={this.enterPressed.bind(this)}
                disabled={this.state.verifying}
                value={this.state.tempPhoneNumber}
                onChange={this.onChangeNumber.bind(this)}
                placeholder=""
              />
            </div>
            <div className="field submit">
              <FormattedMessage id="PhoneCheck.Verify" defaultMessage="Verify">
                {(msg) => (
                  <input
                    type="submit"
                    value={msg}
                    disabled={!this.state.isValidNumber || this.state.verifying}
                    className="btn btn-default"
                    onClick={this.verifyPhoneNumber.bind(this)}
                    onKeyPress={this.enterPressed.bind(this)}
                  />
                )}
              </FormattedMessage>
              {this.state.verifying ? (
                <ReactLoading
                  className="submit loader"
                  type={'spin'}
                  color={'black'}
                  height={30}
                  width={30}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </form>
        {this.state.message ? (
          <Portal node={document && document.getElementById('content')}>
            <div className="error" hidden={!this.state.message}>
              <p>{this.state.message}</p>
            </div>
          </Portal>
        ) : (
          false
        )}
      </div>
    );
  }

  /**
   * This returns the section that only shows the phone number
   * that the user already typed and the option to show the
   * other sections to edit the phone number. This sections is
   * shown when the user sent the phone number to verify.
   */
  onlyPhoneNumber() {
    let num = phoneUtil.parseAndKeepRawInput(this.state.validPhoneNumber);
    let number = phoneUtil.format(num, PNF.INTERNATIONAL);
    var found = CODES.codes.find(
      function (element) {
        return element.value === this.state.selectedCountry.value;
      }.bind(this)
    );
    let countryName = (
      <FormattedMessage id={found.country} defaultMessage={found.country} />
    );
    return (
      <div>
        {this.props.isVerified ? (
          <Portal node={document && document.getElementById('results')}>
            <span>
              {number}
              <button
                className="btn btn-default"
                onClick={this.enableEdit.bind(this)}
              >
                <i className="fa fa-pencil"></i>
              </button>
            </span>
          </Portal>
        ) : (
          //else
          <span className="phone-check">
            <h1>{number}</h1>
            <button
              className="btn btn-default"
              onClick={this.enableEdit.bind(this)}
            >
              <i className="fa fa-pencil"></i>
            </button>
          </span>
        )}
        <h4 className="countryName">{countryName}</h4>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.codeSent ? this.onlyPhoneNumber() : this.mainContent()}
      </div>
    );
  }
}

PhoneCheck.propTypes = {
  kashtag: PropTypes.string.isRequired,
  verificationCodeSent: PropTypes.func.isRequired,
  isVerified: PropTypes.bool.isRequired,
  setIsVerified: PropTypes.func.isRequired,
  codeSent: PropTypes.bool.isRequired,
};

export default PhoneCheck;
