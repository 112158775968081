import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './KashtagCheck.css';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import { checkKashtagAvailability } from '../../Providers/KashProvider';

const kashtagMaxLength = 20; // Max length of the kashtag that can be entered
const kashtagMinLength = 5; // Min length of the kashtag that can be entered
const WAIT_INTERVAL = 2000; // Time to wait before sending the kashtag to the lambda

class KashtagCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false, // shows if the information modal is open or not
      kashtag: '#kashtag', // kashtag typed by the user
      availability: false, // shows if the kashtag entered is available
      message: '', // a message to show to the user
      restrictedChars: '', // shows the restricted characters that the user entered
      checking: false, // shows if the kashtag is being checked by the lambda
    };
    this.kashtagInput = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.timer = null;
    this.kashtagInput.current.setSelectionRange(1, 1);
  }

  /**
   * Communicates with a aws lambda to check if a kashtag is available or not, and
   * sets a message for the user to see depending on the result of the check.
   * @param {string} kashtag The kashtag to be checked
   */
  checkKashtag(kashtag) {
    checkKashtagAvailability(kashtag)
      .then((response) => {
        let message = '';
        if (!response.available) {
          message = (
            <FormattedMessage
              id="KashtagCheck.KashtagTaken"
              defaultMessage="Unavailable kashtag"
            />
          );
        }
        this.props.kashtagCheckReady(response.available, response.kashtag);
        this.setState({
          kashtag: `#${response.kashtag}`,
          availability: response.available,
          message,
          checking: false,
        });
      })
      .catch((error) => {
        this.props.kashtagCheckReady(false);
        this.setState({ checking: false });
      });
  }

  /**
   * Checks if the format of the kashtag that the user entered is valid,
   * so that it can be send to the lambda to check it's availability.
   * @param {string} kashtag The kashtag to be checked
   */
  checkValidFormat(kashtag) {
    const regex =
      /(?:[0-9A-Za-z_\xA9\xAA\xAE\xB5\xB7\xBA\xC0-\xD6\xD8-\xF6\xF8])+/g;
    const inverseRegex = /(?:(?![0-9A-Za-z_]).|__)+/g;
    let regexKashtag = regex.exec(kashtag);
    if (kashtag.length < kashtagMinLength) {
      let message = (
        <FormattedMessage
          id="KashtagCheck.KashtagFormat"
          defaultMessage="Make sure your username is {min} to {max} characters long, with letters, numbers or underscores only"
          values={{ min: kashtagMinLength, max: kashtagMaxLength }}
        />
      );
      this.setState({ message, checking: false }, () => {
        this.props.kashtagCheckReady(false);
      });
      return false;
    } else if (kashtag.length > kashtagMaxLength) {
      let message = (
        <FormattedMessage
          id="KashtagCheck.MaxLength"
          defaultMessage="Reduce it to {max} characters or less"
          values={{ max: kashtagMaxLength }}
        />
      );
      this.setState({ message, checking: false }, () => {
        this.props.kashtagCheckReady(false);
      });
      return false;
    } else if (kashtag === 'kashtag') {
      let message = (
        <FormattedMessage
          id="KashtagCheck.KashtagTaken"
          defaultMessage="Unavailable kashtag"
        />
      );
      this.setState({ message, checking: false }, () => {
        this.props.kashtagCheckReady(false);
      });
      return false;
    } else {
      let restrictedCharacters = kashtag.match(inverseRegex);
      if (restrictedCharacters || regexKashtag[0] !== regexKashtag.input) {
        let message = (
          <FormattedMessage
            id="KashtagCheck.SpecialCharsNotAllowed"
            defaultMessage="Special characters ( {chars} ) are not allowed"
            values={{ chars: restrictedCharacters }}
          />
        );
        this.setState({ message, checking: false }, () => {
          this.props.kashtagCheckReady(false);
        });
        return false;
      } else {
        this.setState({ message: '' });
        return true;
      }
    }
  }

  /**
   * It's called every time the user makes any change to the kashtag
   * input field.
   * @param {object} event
   */
  onChangeText = (event) => {
    let newKashtag = event.target.value.toLowerCase().replace(/\s/g, '');
    if (newKashtag.charAt(0) === '#' && newKashtag.charAt(1) === '#') {
      newKashtag = `#${newKashtag.replace('##', '')}`;
    } else {
      newKashtag = `#${newKashtag.replace('#', '')}`;
    }
    if (newKashtag.length === 0) {
      this.setState({ kashtag: '#' });
    } else {
      this.setState(
        {
          kashtag: newKashtag,
        },
        () => {
          this.validateKashtag(this.state.kashtag.replace('#', ''));
        }
      );
    }
  };

  /**
   * Deletes "kashtag" when the user clicks on the
   * input filed or presses any key
   * @param {object} event
   */
  handleInputKeyDownAndMouseDown = (event) => {
    if (event.type === 'mousedown') {
      let message = (
        <FormattedMessage
          id="KashtagCheck.KashtagFormat"
          defaultMessage="Make sure your username is {min} to {max} characters long, with letters, numbers or underscores only"
          values={{ min: kashtagMinLength, max: kashtagMaxLength }}
        />
      );
      this.setState({ message });
    }
    if (this.state.kashtag === '#kashtag') {
      this.setState({
        kashtag: event.target.value
          .replace('kashtag', '')
          .toLowerCase()
          .replace(/\s/g, ''),
      });
    }
  };

  /**
   * Each time it's called it checks the format of the kashtag and
   * if it is valid it checks the availability.
   * @param {strirng} kashtag
   */
  validateKashtag(kashtag) {
    this.props.kashtagCheckReady(false);
    if (!kashtag.trim()) {
      this.setState({ message: '', checking: false });
      clearTimeout(this.timer);
    } else if (this.checkValidFormat(kashtag)) {
      clearTimeout(this.timer);
      this.setState({ checking: true });
      this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
    } else {
      clearTimeout(this.timer);
    }
  }

  /**
   * This is called when the timer has reached the amount
   * of time set in WAIT_INTERVAL. it calls the function
   * to check the kashtag with the lambda.
   */
  triggerChange() {
    let kashtag = this.state.kashtag.replace('#', '');
    this.checkKashtag(kashtag);
  }

  /**
   * Toggles the state of the modal, to visible and not visible
   * @param {object} event
   */
  toggle(event) {
    event.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
    if (this.state.formSubmitted) {
      window.location.reload();
    }
  }

  /**
   * Enables the section to type the kashtag
   * @param {object} event
   */
  enableEdit(event) {
    event.preventDefault();
    this.props.kashtagCheckReady(false);
    this.setState({ availability: false });
    this.props.verificationCodeSent(false);
    if (this.props.isVerified) {
      this.props.setIsVerified(false);
    }
  }

  /**
   * This returns the sections that lets the user type
   * the kashtag that he/she wants.
   */
  mainContent() {
    return (
      <div>
        <div className="title-question">
          <h1 className="app-title">
            <FormattedMessage
              id="KashtagCheck.Title"
              defaultMessage="Save your kashtag!"
            />
          </h1>
          <button
            className="btn btn-default question"
            data-tooltip="kashtag?"
            onClick={this.toggle}
          >
            <i className="fa fa-question-circle"></i>
          </button>
        </div>
        <div className="form">
          <div className="field">
            <input
              ref={this.kashtagInput}
              autoFocus={true}
              id="kashtagInput"
              value={this.state.kashtag}
              className={
                this.state.message ? 'without-label error' : 'without-label'
              }
              type="text"
              placeholder="#kashtag"
              onChange={this.onChangeText}
              onKeyDown={this.handleInputKeyDownAndMouseDown}
              onMouseDown={this.handleInputKeyDownAndMouseDown}
            />
            {this.state.checking ? (
              <ReactLoading
                className="loader"
                type={'spin'}
                color={'black'}
                height={25}
                width={25}
              />
            ) : (
              false
            )}
          </div>
        </div>
        <ul className="list-unstyled">
          <li>{this.state.message}</li>
        </ul>

        <Portal>
          <Modal
            modalClassName="modalContent"
            size="sm"
            isOpen={this.state.modal}
            toggle={this.toggle}
          >
            <ModalHeader toggle={this.toggle}>
              <div className="modalTitle">
                <FormattedMessage
                  id="KashtagCheck.ModalTitle"
                  defaultMessage="What is a kashtag?"
                />
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="modalBody">
                <FormattedMessage
                  id="KashtagCheck.ModalBody"
                  defaultMessage="A kashtag is the equivalent to a username. With the kashtag is how your contacts are going to be able to identify you in the app. Save it now to have higher possibilities to get the one you like most."
                />
              </div>
            </ModalBody>
            <ModalFooter className="modalFooter">
              <button
                className="btn btn-default modalButton form"
                onClick={this.toggle}
              >
                <FormattedMessage id="App.Ok" defaultMessage="Ok" />
              </button>
            </ModalFooter>
          </Modal>
        </Portal>
      </div>
    );
  }

  /**
   * This returns the sections that only shows the kashtag
   * that the user already typed and the option to show the
   * other sections to edit the kashtag. This sections is
   * shown when the user sent the phone number to verify.
   */
  onlyKashtag() {
    return (
      <div id="results" className="results">
        <span>
          {this.state.kashtag}
          <button
            className="btn btn-default"
            onClick={this.enableEdit.bind(this)}
          >
            <i className="fa fa-pencil"></i>
          </button>
        </span>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.availability ? this.onlyKashtag() : this.mainContent()}
      </div>
    );
  }
}

KashtagCheck.propTypes = {
  kashtagCheckReady: PropTypes.func.isRequired,
  isVerified: PropTypes.bool.isRequired,
  setIsVerified: PropTypes.func.isRequired,
  verificationCodeSent: PropTypes.func.isRequired,
};

export default KashtagCheck;
