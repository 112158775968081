/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CodeVerification.css';
import { Portal } from 'react-portal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactCodeInput from 'react-code-input';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';

import {
  confirmRegistration,
  sendMFACode,
} from '../../Providers/CognitoProvider';

const CODE_LENGTH = 6; // sets the code length

class CodeVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifying: false, // shows if the code has been sent to cognito to be verified
      isVerified: false, // shows if the user has been successfully verified in cognito
      errorMessage: '', // shows an erro message when necessary
      modal: false, // shows if the phone number entered already exists and is confirmed and completed
      codeInputStyle: {
        // style for the input code field
        background: 'transparent',
        border: 'none',
        borderBottom: '2px solid #e0e2e2',
        marginRight: '7px',
        textAlign: 'center',
        width: '45px',
        height: '45px',
        fontSize: '100%',
        letterSpacing: '10px',
        fontFamily: 'm-m',
        color: '#828c8c',
      },
    };
    this.toggle = this.toggle.bind(this);
  }

  /**
   * It is called everytime the user types into the code input,
   * when the code is complete it is sent to cognito to verify
   * the user's account.
   * @param {string} code code entered by the user
   */
  verifyCode(code) {
    if (code.length === CODE_LENGTH) {
      this.setState({ verifying: true });
      if (sessionStorage.getItem('confirmed_user') === 'true') {
        sendMFACode(code, {
          onSuccess: (userSession) => {
            sessionStorage.setItem(
              'access_token',
              userSession.getAccessToken().getJwtToken()
            );
            sessionStorage.setItem(
              'id_token',
              userSession.getIdToken().getJwtToken()
            );
            if (userSession.idToken.payload['custom:completed'] === true) {
              this.setState({
                errorMessage: (
                  <FormattedMessage
                    id="CodeVerification.PhoneAlreadyExists"
                    defaultMessage="That phone number is already linked to a kashtag, please try with a different number."
                  />
                ),
                verifying: false,
                modal: true,
              });
            } else {
              this.props.setIsVerified(true);
              this.setState({ isVerified: true, verifying: false });
            }
          },
          onFailure: (error) => {
            let errorMessage = '';
            let verifying = false;
            switch (error.name) {
              case 'ExpiredCodeException':
                errorMessage = (
                  <FormattedMessage
                    id="CodeVerification.ExpiredCodeException"
                    defaultMessage="The code expired, please verify your phone number"
                  />
                );
                break;
              case 'CodeMismatchException':
                errorMessage = (
                  <FormattedMessage
                    id="CodeVerification.CodeMismatchException"
                    defaultMessage="Invalid verification code provided, please try again"
                  />
                );
                break;
              case 'NotAuthorizedException':
                verifying = true;
                errorMessage = (
                  <FormattedMessage
                    id="PhoneCheck.LimitExceededException"
                    defaultMessage="Attempt limit exceeded, please try again after some time"
                  />
                );
                break;
              default:
                break;
            }
            this.setState({ errorMessage, verifying });
          },
        });
      } else {
        confirmRegistration(code, (err, result) => {
          if (err) {
            let errorMessage = '';
            let verifying = false;
            switch (err.name) {
              case 'CodeMismatchException':
                errorMessage = (
                  <FormattedMessage
                    id="CodeVerification.CodeMismatchException"
                    defaultMessage="Invalid verification code provided, please try again"
                  />
                );
                break;
              case 'LimitExceededException':
                verifying = true;
                errorMessage = (
                  <FormattedMessage
                    id="PhoneCheck.LimitExceededException"
                    defaultMessage="Attempt limit exceeded, please try after some time"
                  />
                );
                break;
              case 'NotAuthorizedException':
                verifying = true;
                errorMessage = (
                  <FormattedMessage
                    id="App.Error"
                    defaultMessage="Oops!! We have registered a problem, but we'll solve it so it won't happen again"
                  />
                );
                break;
              default:
                break;
            }
            this.setState({ verifying, errorMessage });
            return;
          }
          this.props.setIsVerified(true);
          this.setState({ isVerified: true, verifying: false });
        });
      }
    }
    this.setState({ errorMessage: '' });
  }

  /**
   * Toggles the state of the modal, to visible and not visible
   * @param {object} event
   */
  toggle(event) {
    event.preventDefault();
    this.setState(
      {
        modal: !this.state.modal,
      },
      () => {
        this.props.setIsVerified(false);
        this.props.verificationCodeSent(false);
      }
    );
  }

  /**
   * his returns the sections that lets the user type
   * the verification code.
   */
  mainContent() {
    return (
      <div>
        <h1>
          <FormattedMessage
            id="CodeVerification.Title"
            defaultMessage="Enter the code received via SMS"
          />
        </h1>

        {!this.state.verifying ? (
          <div className="all-form">
            <div className="code-input">
              <ReactCodeInput
                inputStyle={this.state.codeInputStyle}
                type="number"
                fields={CODE_LENGTH}
                autoFocus
                onChange={this.verifyCode.bind(this)}
              />
            </div>
          </div>
        ) : (
          <ReactLoading
            className="codeLoader"
            type="spin"
            color="black"
            height={35}
            width={35}
          />
        )}

        <Portal node={document && document.getElementById('content')}>
          <div
            className="error"
            hidden={!this.state.errorMessage || this.state.modal}
          >
            <p>{this.state.errorMessage}</p>
          </div>
        </Portal>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.isVerified ? false : this.mainContent()}

        <Portal>
          <Modal
            size="sm"
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader>
              <div className="modalTitle">
                <FormattedMessage id="App.Oops" defaultMessage="Oops!!" />
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="modalBody">{this.state.errorMessage}</div>
            </ModalBody>
            <ModalFooter className="modalFooter">
              <button
                className="btn btn-default modalButton"
                onClick={this.toggle}
                type="button"
              >
                <FormattedMessage id="App.Ok" defaultMessage="Ok" />
              </button>
            </ModalFooter>
          </Modal>
        </Portal>
      </div>
    );
  }
}

CodeVerification.propTypes = {
  setIsVerified: PropTypes.func.isRequired,
  verificationCodeSent: PropTypes.func.isRequired,
};

export default CodeVerification;
