import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import BotBase from '../../../Components/Bot/Base';
import delay from '../../../utils/general-utils';

const OpenWhatsappUserStatus = ({ match }) => {
  const appLink = 'kash://app/whatsapp-user-status';
  const [init, setInit] = useState(true);
  const [notInstaled, setNotInstaled] = useState(false);

  useEffect(() => {
    if (init) {
      // eslint-disable-next-line no-use-before-define
      loadKashSupportApp();
      setInit(false);
    }
  }, [init]);

  const loadKashSupportApp = async () => {
    await delay(100);
    window.location = appLink;
    setTimeout(() => {
      if (isMobile) {
        setNotInstaled(true);
      }
    }, 150);
  };

  return (
    <BotBase onPlatformError={notInstaled} routeParams={match}>
      <a href={appLink} style={{ color: '#334c66' }}><FormattedMessage id="Bot.WhatsappStaus.Go" defaultMessage="Kash-Whatsapp" /></a>
    </BotBase>
  );
};


export default OpenWhatsappUserStatus;
