import React, { Component } from 'react';
import moment from 'moment';
import './footer.scss';

/**
 * Displays two counters to let the user know
 * how many kashtags have been reserved and
 * how many have been activated.
 */
class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p>
          Kash ©
          {moment().format('YYYY')}
        </p>
      </div>
    );
  }
}

export default Footer;
