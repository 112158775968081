import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getKashtagCounters } from '../../Providers/KashProvider';

/**
 * Displays two counters to let the user know
 * how many kashtags have been reserved and
 * how many have been activated.
 */
class KashtagCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservedCounter: 0,
    };
  }

  componentDidMount() {
    getKashtagCounters()
      .then((response) => {
        if (response.message) {
          return;
        }
        this.setState({ reservedCounter: response.reserved });
      })
      .catch((err) => {
        console.log(err);
        //  throw new Error(err);
      });
  }

  render() {
    const { reservedCounter } = this.state;
    return (
      <div className="counterContainer">
        <div className="counter">
          <p>
            {`${reservedCounter} `}
            <FormattedMessage
              id="KashtagCounter.Reserved"
              defaultMessage="Reserved:"
            />
          </p>
        </div>
      </div>
    );
  }
}

export default KashtagCounter;
