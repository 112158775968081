import React, { Component } from "react";
import "../../css/style.css";
import "../../css/media.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import KashtagCheck from "../../Components/KashtagCheck/KashtagCheck";
import PhoneCheck from "../../Components/PhoneCheck/PhoneCheck";
import CodeVerification from "../../Components/CodeVerification/CodeVerification";
import UserForm from "../../Components/UserForm/UserForm";
import KashtagCounter from "../../Components/KashtagCounter/KashtagCounter";
import logo from "../../imgs/kash-logo.png";
import envVars from '../../Data/envs';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // shows if the kashtag has been properly checked and it is available
      kashtagChecked: false,
      // the valid kashtag typed by the user
      kashtag: "",
      // shows if the verifications code for the phone number has been sent to the user
      codeSent: false,
      // shows if the user has been successfully verified in cognito
      isVerified: false,
      // shows if the timer for the verification has finished
      timerFinished: false,
      // shows if the user has finished the process or not
      processFinished: false,
    };
  }

  /**
   * It's called from KashtagCheck.js to let App.js that
   * the kashtag has been validated and the PhoneCheck.js
   * component can be shown.
   * @param {boolean} kashtagChecked - True if the kashtag has been checked
   * and is valid and available, false otherwise
   * @param {string} kashtag - The kashtag typed by the user
   */
  kashtagCheckReady = (kashtagChecked, kashtag = "") => {
    this.setState({ kashtagChecked, kashtag });
  };

  /**
   * It's called from PhoneCheck.js to let App.js that
   * the the code has been sent to the user.
   * @param {boolean} codeSent - True if the code has been sent, false otherwise
   */
  verificationCodeSent = (codeSent) => {
    this.setState({ codeSent });
  };

  /**
   * It is called from CodeVerification to let App.js know that
   * the user has entered the verification code and now is
   * confirmed in cognito.
   *
   * If the user can return to the phone verifications after the code has been verified, this has
   * to be set to false again. (TODO)
   *
   * @param {boolean} isVerified - True if the user is verified, false otherwise
   */
  setIsVerified = (isVerified) => {
    this.setState({ isVerified });
  };

  /**
   * It is called from UserForm.js to let App.js know that
   * the timer has reached 0 and the user has to restart
   * the process.
   * @param {boolean} timerFinished - True if the timer reached zero, false otherwise
   */
  setTimerFinished = (timerFinished) => {
    const { processFinished } = this.state;
    this.setState({ timerFinished });
    if (processFinished) {
      this.refresh();
    }
  };

  /**
   * It's called from UserForm.js to let App.js know
   * that the process has been successfully completed.
   * @param {boolean} processFinished - True if the process has been completed, false otherwise
   */
  setProcessFinished = (processFinished) => {
    this.setState({ processFinished });
  };

  /**
   * Refreshes the website
   */
  refresh = () => {
    window.location.reload();
  };

  render() {
    const {
      isVerified,
      kashtagChecked,
      timerFinished,
      kashtag,
      codeSent,
      processFinished,
    } = this.state;
    return (
      <div>
        <div className="body">
          <header>
            <img
              src={logo}
              alt="Logo"
              className={
                envVars.REACT_APP_HIDE === "true"
                  ? "img-fluid full"
                  : "img-fluid"
              }
            />
            {envVars.REACT_APP_HIDE === "true" ? false : <KashtagCounter />}
            {envVars.REACT_APP_HIDE === "true" ? (
              false
            ) : (
              <div id="content" className="content">
                <div className="content-box">
                  <KashtagCheck
                    kashtagCheckReady={this.kashtagCheckReady}
                    isVerified={isVerified}
                    setIsVerified={this.setIsVerified}
                    verificationCodeSent={this.verificationCodeSent}
                  />

                  {kashtagChecked && !timerFinished ? (
                    <PhoneCheck
                      kashtag={kashtag}
                      verificationCodeSent={this.verificationCodeSent}
                      setIsVerified={this.setIsVerified}
                      codeSent={codeSent}
                      isVerified={isVerified}
                    />
                  ) : (
                    false
                  )}

                  {codeSent && !timerFinished && kashtagChecked ? (
                    <CodeVerification
                      setIsVerified={this.setIsVerified}
                      verificationCodeSent={this.verificationCodeSent}
                    />
                  ) : (
                    false
                  )}

                  {codeSent && isVerified && !timerFinished ? (
                    <UserForm
                      setProcessFinished={this.setProcessFinished}
                      setTimerFinished={this.setTimerFinished}
                      kashtag={kashtag}
                    />
                  ) : (
                    false
                  )}
                </div>
              </div>
            )}
          </header>

          <Modal size="sm" isOpen={timerFinished && !processFinished}>
            <ModalHeader>
              <div className="modalTitle">
                <FormattedMessage
                  id="App.WhatAShame"
                  defaultMessage="What a shame!"
                />
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="modalBody">
                <FormattedMessage
                  id="App.RunOutOfTime"
                  defaultMessage="You have run out of time. The kashtag and phone number wont be saved."
                />
              </div>
            </ModalBody>
            <ModalFooter className="modalFooter">
              <button
                type="button"
                className="btn btn-default modalButton"
                onClick={this.refresh}
              >
                <FormattedMessage id="App.Ok" defaultMessage="Ok" />
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}


export default Home;