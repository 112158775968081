import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './scss/custom.scss';
import { IntlProvider } from 'react-intl';

import messages_es from './translations/es.json';
import messages_en from './translations/en.json';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const messages = {
  es: messages_es,
  en: messages_en,
};
const language = navigator.language.split(/[-_]/)[0]; // language without region code


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={messages[language]}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
