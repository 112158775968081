import './App.css';
import { Helmet } from "react-helmet";
import MainRoutes from "./routes/mainRoute";
import logo from "./imgs/kash-logo.png";

function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={logo} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="Kash" />
        <meta name="keywords" content="Kash" />
        <meta name="author" content="..." />
      </Helmet>
      <MainRoutes />
    </div>
  );
}

export default App;
