import axios from 'axios';
import envVars from '../Data/envs';
export const BASE_URL = (envVars.REACT_APP_STAGE === 'prod') ? 'https://api.adios.cash' : 'https://api-dev.adios.cash';

/**
 * Verifies if the kashtag entered by the user is still available or not
 * @param {string} kashtag - Kashtag entered by the user
 * @returns {promise} - Response from the API
 */
export const checkKashtagAvailability = kashtag => fetch(`${BASE_URL}/users/verify_kashtag?kashtag=${kashtag}`)
  .then(response => response.json());

/**
 * Gets the terms and conditions from a lambda
 * @returns {promise} - Response from the API
 */
export const getTermsAndConditions = () => fetch(`${BASE_URL}/terms-conditions/0`)
  .then(response => response.json());

/**
 * Gets the reserved and activated kashtags
 * counters from a lambda
 * @returns {promise} - Response from the API
 */
export const getKashtagCounters = () => fetch(`${BASE_URL}/kashtag-counter`)
  .then(response => response.json());

/**
 * Sends the data to update the user attributes when the reservation process is finished
 * @param {string} identification - User's id number
 * @param {string} firstName - User's first name
 * @param {string} lastName - User's last name
 * @param {string} email - User's email
 * @param {string} kashtag - User's kashtag
 * @returns {promise} - Response from the API
 */
export const updateUserAttributes = (identification, firstName, lastName, email, kashtag) => {
  const requestData = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('id_token'),
    },
    body: JSON.stringify({
      reservation: true,
      accessToken: sessionStorage.getItem('access_token'),
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        identification,
        kashtag,
      },
    }),
  };
  return fetch(`${BASE_URL}/users`, requestData)
    .then(response => response.json());
};

/**
 * Sends the data to update the user email when the reservation process is finished
 * @param {string} email - User's email
 * @returns {promise} - Response from the API
 */
export const updateUserEmail = (email) => {
  const requestData = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('id_token'),
    },
    body: JSON.stringify({
      accessToken: sessionStorage.getItem('access_token'),
      user: {
        email,
      },
    }),
  };
  return fetch(`${BASE_URL}/users`, requestData)
    .then(response => response.json());
};

/**
 * Gets the depp_link of the user
 * @returns {token} - token by url
 * @returns {webAgent} - web agent
 * @returns {ip} - ip
 */
export const getDeepLink = async (token) => {
  try {
    const { data } = await axios.post(envVars.URL_API, { token }, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': envVars.API_KEY,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getDeepLinkDynamic = async (nanoId) => {
  try {
    const { data } = await axios.post(envVars.URL_API_DYNAMIC, { nanoId }, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': envVars.API_KEY,
      },
    });
    return data;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};
