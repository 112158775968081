import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  BrowserView, MobileView,
} from 'react-device-detect';
import '../../css/style.css';
import '../../css/media.css';
import ReactLoading from 'react-loading';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import Footer from '../../Components/footer/footer';
import { getDeepLinkDynamic } from '../../Providers/KashProvider';
import { PLAY_STORE_URL, APP_STORE_URL } from '../../Data/constants';
import deviceDetector from '../../utils/deviceDetector';
import envVars from '../../Data/envs';
const LOGO = 'https://dev.kashtag.me/b292fa55bb54e58b1a32506b60b2e1e1.png';

const QrDynamic = () => {
  const { token } = useParams();
  const { isAndroid, isIOS } = deviceDetector();
  const [deepLink, setDeepLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  const redirectTo = isAndroid ? PLAY_STORE_URL : APP_STORE_URL;
  const openApp = (deepLink) => {
    if (isAndroid || isIOS) {
      window.location.replace(deepLink);
      setTimeout(() => {
        window.location.replace(
          redirectTo,
        );
      }, 3000);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const data = await getDeepLinkDynamic(token);
          if (data) {
            setDeepLink(data.deepLink);
            openApp(data.deepLink);
          } else {
            setModal(true);
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [token]);

  const renderLoading = () => (
    <ReactLoading type="spin" color="#505050" height={100} width={100} />
  );

  const renderModal = () => (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(false)}>
        ¡Escanea un codigo córrecto!
      </ModalHeader>
      <ModalBody>
        Aseguráte que estás enviando o solicitando dinero de la persona correcta
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setModal(false)}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <div>
      {renderModal()}
      <div className="body">
        <header>
          <img
            src={LOGO}
            alt="Logo"
            className={
              envVars.REACT_APP_HIDE === 'true'
                ? 'img-fluid full'
                : 'img-fluid'
            }
          />

          {envVars.REACT_APP_HIDE === 'true' ? (
            false
          ) : (
            <div id="content" className="content content-qr">
              {loading ? (
                <div className="content-loading">{renderLoading()}</div>
              ) : (
                <div className="content-box-qr">
                  {deepLink !== '' && <p className="text-go">Abrir en Kash</p>}
                  <BrowserView>
                    <a href="https://kashlatam.com/" className="button-go">
                      Descargar
                    </a>
                  </BrowserView>
                  <MobileView>
                    {deepLink !== '' && (
                      <button
                        className="button-go"
                        onClick={() => openApp(deepLink)}
                      >
                        Abrir
                      </button>
                    )}
                  </MobileView>
                  <MobileView>
                    <hr />
                    <p>¿Aún no tienes Kash?</p>
                    <a href={redirectTo}>Descargar</a>
                  </MobileView>
                </div>
              )}
            </div>
          )}
        </header>
        <Footer />
      </div>
    </div>
  );
};

export default QrDynamic;
