import React, { useEffect, useState } from 'react';
import { isMobile, isAndroid } from 'react-device-detect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import envVars from '../../../Data/envs';
import '../../../css/style.css';
import '../../../css/media.css';

const LOGO = 'https://kashtag.me/b292fa55bb54e58b1a32506b60b2e1e1.png';

const checkDevice = () => ({
  isMobile,
  isAndroid,
});

const styles = {
  downloadGeneralContainer: {
    backgroundColor: '#dcecec',
    height: '200px',
    marginTop: '-10%',
  },
  textDownload: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '5%',
  },
  downloadLinkContainer: {
    with: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const BotBase = ({ children, onPlatformError, intl }) => {
  const params = useParams;
  const [downloadLink, setDownLoadLink] = useState(null);
  const [showDownLoadApp, setShowDownLoadApp] = useState(true);
  useEffect(() => {
    const device = checkDevice();
    if (!device.isMobile) {
      const noMobile = intl.formatMessage({ id: 'Bot.Base.NoMobile' });
      alert(noMobile);
      let country = null;
      if (params) ({ country } = params);
      let kashWebSite = 'https://kashlatam.com';
      if (country) kashWebSite += `/${country}`;
      setTimeout(() => {
        window.location = kashWebSite;
      }, 150);
    } else {
      setDownLoadLink(
        device.isAndroid
          ? 'https://play.google.com/store/apps/details?id=com.namutek.kash'
          : 'https://apps.apple.com/app/kash/id1476122795',
      );
    }
  }, []);

  useEffect(() => {
    if (onPlatformError && isMobile) {
      // setShowDownLoadApp(true);
    }
  }, [onPlatformError]);

  return (
    <React.Fragment>
      <header style={!showDownLoadApp ? { height: '100vh' } : {}}>
        <img
          src={LOGO}
          alt="Logo"
          className={
            envVars.REACT_APP_HIDE === 'true'
              ? 'img-fluid full'
              : 'img-fluid'
          }
        />
        {children}
      </header>
      {showDownLoadApp ? (
        <div style={styles.downloadGeneralContainer}>
          <p style={styles.textDownload}>
            <FormattedMessage
              id="Bot.Base.Download"
              defaultMessage="Download kash app"
            />
          </p>

          <div style={styles.downloadLinkContainer}>
            <a href={downloadLink} className="button-go" style={styles.linkBtn}>
              <FormattedMessage
                id="Bot.Base.Download.Btn"
                defaultMessage="Download"
              />
            </a>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default injectIntl(BotBase);
