import React from 'react';
import './ButtonSecondary.scss';

const ButtonPrimary = ({ children, onClick }) => (
  <button
    id="download-btn"
    className="btn btn-secondary text-uppercase"
    type="button"
    htmlFor="secondary"
    onClick={onClick}
  >
    {children}
  </button>
);
export default ButtonPrimary;
