import React, { useState } from 'react';

import Footer from '../../Components/footer/footer';
import Swiper from './referralsComponents/Swiper';
import ButtonGroup from './referralsComponents/ButtonGroup';
import deviceDetector from '../../utils/deviceDetector';
import {
  SlideA,
  SlideB,
  SlideC,
  SlideD,
  SlideE,
} from './referralsComponents/Slide';
import { useQuery, useAppDetector as onAppDetectorFunction } from '../../hooks';
import { PLAY_STORE_URL, APP_STORE_URL } from '../../Data/constants';

const slides = [SlideA, SlideB, SlideC, SlideD, SlideE];
const referredDeeplink = ' kash://app/referred';

export default function Referred() {
  const [activeSlide, setActiveSlide] = useState(0);
  const { isMobile, isAndroid } = deviceDetector();

  const query = useQuery();
  const referredCode = query.get('code');

  const handlePressMainBtn = () => {
    goToNextSlide(activeSlide + 1);
  };

  const goToNextSlide = (newActiveSlide) => {
    setActiveSlide(newActiveSlide);
  };

  const handlePressSecondaryBtn = () => {
    const redirectTo = isAndroid ? PLAY_STORE_URL : APP_STORE_URL;

    onAppDetectorFunction({
      deeplink: `${referredDeeplink}?code=${referredCode}`,
      onAppDetected: ({ isAppInstalled }) => {
        if (isMobile && !isAppInstalled) {
          window.location.replace(redirectTo);
        }
      },
    });
  };

  return (
    <div className="container d-flex flex-column p-4">
      <Swiper
        containerClassName="mt-4 mb-4 swiper-container"
        slides={slides}
        activeSlide={activeSlide}
        onChangeActiveSlide={goToNextSlide}
      />

      <ButtonGroup
        containerClassName="d-flex justify-content-center flex-column align-items-center gap-4 col-xs-12 mt-5"
        isVisibleMainBtn={activeSlide !== slides.length - 1}
        onPressMainBtn={handlePressMainBtn}
        onPressSecondaryBtn={handlePressSecondaryBtn}
      />

      <Footer />
    </div>
  );
}
