import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonPrimary, ButtonSecondary } from '../../../../Components/Button';
import './ButtonGroup.scss';

const ButtonGroup = ({
  containerClassName,
  isVisibleMainBtn,
  onPressMainBtn = () => {},
  onPressSecondaryBtn = () => {},
}) => (
  <div className={`${containerClassName} container-btn`}>
    {isVisibleMainBtn
      ? (
        <React.Fragment>
          <ButtonPrimary onClick={onPressMainBtn}>
            <FormattedMessage id="App.Next" defaultMessage="Siguiente" />
          </ButtonPrimary>
          <ButtonSecondary onClick={onPressSecondaryBtn}>
            <FormattedMessage
              id="App.DownloadKash"
              defaultMessage="Descargar Kash"
            />
          </ButtonSecondary>
        </React.Fragment>
      )
      : (
        <ButtonPrimary onClick={onPressSecondaryBtn} isThereOnlyOneBth={!isVisibleMainBtn}>
          <FormattedMessage
            id="App.DownloadKash"
            defaultMessage="Descargar Kash"
          />
        </ButtonPrimary>
      )}

  </div>
);
export default ButtonGroup;
