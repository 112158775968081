import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import BotBase from '../../../Components/Bot/Base';
import delay from '../../../utils/general-utils';

const OpenSupportKash = () => {
  const [init, setInit] = useState(true);
  const [notInstaled, setNotInstaled] = useState(false);

  useEffect(() => {
    if (init) {
      // eslint-disable-next-line no-use-before-define
      loadKashSupportApp();
      setInit(false);
    }
  }, [init]);

  const loadKashSupportApp = async () => {
    await delay(100);
    window.location = 'kash://app/support';
    setTimeout(() => {
      if (isMobile) {
        setNotInstaled(true);
      }
    }, 150);
  };

  return (
    <BotBase onPlatformError={notInstaled}>
      <a href="kash://app/support" style={{ color: '#334c66' }}>
        <FormattedMessage id="Bot.Support.Go" defaultMessage="Support" />
      </a>
    </BotBase>
  );
};

export default OpenSupportKash;
