import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UserForm.css';
import { Portal } from 'react-portal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import FloatingLabelInput from '../react-floating-label-input';
import '../react-floating-label-input/dist/react-floating-label-input.css';
import {
  updateUserAttributes,
  getTermsAndConditions,
  updateUserEmail,
} from '../../Providers/KashProvider';
import {
  authenticateUser,
  enableMFA,
  signOut,
} from '../../Providers/CognitoProvider';
import Timer from '../Timer/Timer';

const { decode } = require('html-encoder-decoder');

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '', // user's email
      emailMessage: '', // error message for email to show if necessary
      firstName: '', // user's first name
      firstNameMessage: '', // error message for first name to show if necessary
      lastName: '', // user's last name
      lastNameMessage: '', // error message for last name to show if necessary
      idNumber: '', // users's identification number
      idNumberMessage: '', // error message for identification number to show if necessary
      termsAccepted: false, // user accepted or not the terms and conditions
      isValidEmail: false, // shows if the email entered by the user is valid
      isValidForm: false, // shows if all the data entered by the user is valid
      errorMessage: '', // shown when there is a server error or other type of error
      modal: false, // shows if the terms and conditions modal is open or not
      modalTitle: (
        <FormattedMessage
          id="UserForm.TnC"
          defaultMessage="terms and conditions"
        />
      ),
      modalBody: '', // information that will show in the modal body
      formSubmitted: false, // shows if the form has been successfully submitted
      processing: false, // shows if the call to the lambda is processing or not
      processFinished: false, // shows if the user has finished the process or not
      timerVisible: false, // tells if the timer can be visible or not
    };
    getTermsAndConditions()
      .then((data) => {
        this.setState({ modalBody: decode(data.document) });
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  componentDidMount() {
    this.timer.startTimer();
    if (sessionStorage.getItem('confirmed_user') === 'false') {
      authenticateUser('', {
        onSuccess: (userSession) => {
          sessionStorage.setItem(
            'access_token',
            userSession.getAccessToken().getJwtToken()
          );
          sessionStorage.setItem(
            'id_token',
            userSession.getIdToken().getJwtToken()
          );
          enableMFA((err) => {
            if (err) {
              throw new Error(err);
            }
          });
        },
        onFailure: (err) => {
          throw new Error(err);
        },
        mfaRequired: () => {
          // shouldn't enter here, ever
          const errorMessage = (
            <FormattedMessage
              id="App.Error"
              defaultMessage="Oops!! We have registered a problem, but we'll solve it so it won't happen again"
            />
          );
          this.setState({ errorMessage });
        },
      });
    }
  }

  /**
   * It's called everytime the email field changes.
   * It sets the email state to what the user types.
   * @param {object} event
   */
  onEmailChange = (event) => {
    const { email } = this.state;
    this.setState(
      {
        email: event.target.value,
      },
      () => {
        this.checkFormValidity();
        this.validateEmail(email);
      }
    );
  };

  /**
   * It's called everytime the email input field
   * looses focus. It checks if the email is valid
   * and if it is, then it's sent to cognito.
   * @param {object} event
   */
  onEmailBlur = (event) => {
    const { isValidEmail, email } = this.state;
    this.validateEmail(event.target.value);
    if (isValidEmail) {
      updateUserEmail(email).catch((error) => {
        throw new Error(error);
        this.setState({ processing: false });
      });
    }
  };

  /**
   * Validates that the email entered by the user has a valid
   * format and shows a message if it is not valid or is
   * missing.
   * @param {string} email - Email address to check
   */
  validateEmail = (newEmail) => {
    const { email } = this.state;
    let validEmail;
    if (this.validateEmailFormat(newEmail)) {
      this.setState({ emailMessage: '' });
      validEmail = true;
    } else {
      validEmail = false;
      let emailMessage = '';
      if (!email.trim()) {
        emailMessage = (
          <FormattedMessage
            id="UserForm.EmailRequired"
            defaultMessage="Email is required"
          />
        );
      } else {
        emailMessage = (
          <FormattedMessage
            id="UserForm.InvalidEmail"
            defaultMessage="Please enter a valid email"
          />
        );
      }
      this.setState({ emailMessage });
    }
    this.setState(
      {
        isValidEmail: validEmail,
      },
      () => {
        this.checkFormValidity();
      }
    );
  };

  /**
   * It's called everytime the first name field changes.
   * It sets the firstName state to what the user types.
   * @param {object} event
   */
  onFirstNameChange = (event) => {
    this.setState(
      {
        firstName: event.target.value,
      },
      () => {
        const { firstName } = this.state;
        this.checkFormValidity();
        this.setFirstNameMessage(firstName);
      }
    );
  };

  /**
   * Checks if the firstName is blank and sets an
   * error message in case it is
   * @param {string} firstName
   */
  setFirstNameMessage = (firstName) => {
    let firstNameMessage = '';
    if (!firstName.trim()) {
      firstNameMessage = (
        <FormattedMessage
          id="UserForm.NameRequired"
          defaultMessage="First name is required"
        />
      );
    }
    this.setState({ firstNameMessage });
  };

  /**
   * It's called everytime the last name field changes.
   * It sets the lastName state to what the user types.
   * @param {object} event
   */
  onLastNameChange = (event) => {
    this.setState(
      {
        lastName: event.target.value,
      },
      () => {
        const { lastName } = this.state;
        this.checkFormValidity();
        this.setLastNameMessage(lastName);
      }
    );
  };

  /**
   * Checks if the lastName is blank and sets an
   * error message in case it is
   * @param {string} firstName
   */
  setLastNameMessage = (lastName) => {
    let lastNameMessage = '';
    if (!lastName.trim()) {
      lastNameMessage = (
        <FormattedMessage
          id="UserForm.LastNameRequired"
          defaultMessage="Last name is required"
        />
      );
    }
    this.setState({ lastNameMessage });
  };

  /**
   * It's called everytime the idNumber field changes.
   * It sets the idNumber state to what the user types.
   * @param {object} event
   */
  onIdNumberChange = (event) => {
    this.setState(
      {
        idNumber: event.target.value,
      },
      () => {
        const { idNumber } = this.state;
        this.checkFormValidity();
        this.setIdNumberMessage(idNumber);
      }
    );
  };

  /**
   * Checks if the idNumber is blank and sets an
   * error message in case it is
   * @param {string} idNumber
   */
  setIdNumberMessage = (idNumber) => {
    let idNumberMessage = '';
    if (!idNumber.trim()) {
      idNumberMessage = (
        <FormattedMessage
          id="UserForm.IdNumberRequired"
          defaultMessage="Id Number is required"
        />
      );
    }
    this.setState({ idNumberMessage });
  };

  /**
   * It's called everytime the terms and conditions checkbox changes.
   * It sets the termsAccepted state to what the user chose.
   * @param {object} event
   */
  onCheckboxClick = () => {
    this.setState(
      (prevState) => ({
        termsAccepted: !prevState.termsAccepted,
      }),
      () => {
        this.checkFormValidity();
      }
    );
  };

  /**
   * Checks if all the fields in the form are valid,
   * so it can be sent to cognito
   */
  checkFormValidity = () => {
    const {
      isValidEmail,
      email,
      firstName,
      lastName,
      termsAccepted,
      idNumber,
    } = this.state;
    if (
      isValidEmail &&
      email.trim() &&
      firstName.trim() &&
      lastName.trim() &&
      idNumber.trim() &&
      termsAccepted
    ) {
      this.setState({ isValidForm: true });
    } else {
      this.setState({ isValidForm: false });
    }
  };

  /**
   * It's called when the user submits the form. It checks
   * if the form is valid and starts a session in cognito
   * to get the user access tokens and updates the user's
   * attributes.
   */
  submit = (event) => {
    const {
      isValidForm,
      modal,
      formSubmitted,
      processing,
      idNumber,
      firstName,
      lastName,
      email,
    } = this.state;
    const { kashtag, setProcessFinished } = this.props;
    if (event) event.preventDefault();
    this.checkFormValidity();
    this.setState({ processing: true });
    if (isValidForm && !modal && !formSubmitted && !processing) {
      updateUserAttributes(idNumber, firstName, lastName, email, kashtag)
        .then(() => {
          this.setState({
            modal: true,
            errorMessage: '',
            formSubmitted: true,
            modalTitle: (
              <FormattedMessage id="UserForm.Great" defaultMessage="Great!!" />
            ),
            modalBody: (
              <FormattedMessage
                id="UserForm.KashtagSaved"
                defaultMessage="Your kashtag has been successfully saved"
              />
            ),
          });
          setProcessFinished(true);
          this.setState({ processFinished: true }, () => {
            signOut();
          });
        })
        .catch((error) => {
          this.setState({ processing: false });
        });
    }
  };

  /**
   * Toggles the state of the modal, to visible and not visible
   * @param {object} event
   */
  toggle = (event) => {
    const { modal, formSubmitted } = this.state;
    event.preventDefault();
    this.setState({
      modal: !modal,
    });
    if (formSubmitted) {
      window.location.reload();
    }
  };

  /**
   * It's called from Timer.js to let App.js know that
   * the timer has reached the time limit set for it
   * to be visible for the user.
   * @param {boolean} timerVisible true if the timer can be visible, false otherwise
   */
  setTimerVisible = (timerVisible) => {
    this.setState({ timerVisible });
  };

  /**
   * It is called from Timer.js to let UserForm.js know that
   * the timer has reached 0 and inform App.js so the
   * user restarts the process.
   * @param {boolean} timerFinished true if the timer reached zero, false otherwise
   */
  setTimerFinished = (timerFinished) => {
    const { firstName, lastName, email } = this.state;
    const { setTimerFinished } = this.props;
    setTimerFinished(timerFinished);
  };

  validateEmailFormat(emailProp) {
    const tester =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (!emailProp) return false;

    if (emailProp.length > 254) return false;

    const valid = tester.test(emailProp);
    if (!valid) return false;

    // Further checking of some things regex can't handle
    const parts = emailProp.split('@');
    if (parts[0].length > 64) return false;

    const domainParts = parts[1].split('.');
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    ) {
      return false;
    }

    return true;
  }

  render() {
    const {
      firstNameMessage,
      lastNameMessage,
      idNumberMessage,
      processFinished,
      termsAccepted,
      emailMessage,
      timerVisible,
      errorMessage,
      isValidForm,
      modalTitle,
      processing,
      modalBody,
      firstName,
      lastName,
      idNumber,
      modal,
      email,
    } = this.state;
    return (
      <div>
        <form>
          <div className="all-input">
            <div className="input" disabled>
              <FloatingLabelInput
                id="email"
                label={
                  <FormattedMessage
                    id="UserForm.Email"
                    defaultMessage="Email"
                  />
                }
                onChange={this.onEmailChange}
                shrink={90}
                value={email}
                onBlur={this.onEmailBlur}
              />
            </div>
            <span className="error">{emailMessage}</span>
            <div className="input floating">
              <FloatingLabelInput
                id="firstName"
                label={
                  <FormattedMessage
                    id="UserForm.FirstName"
                    defaultMessage="First name"
                  />
                }
                onChange={this.onFirstNameChange}
                shrink={90}
                value={firstName}
              />
            </div>
            <span className="error">{firstNameMessage}</span>
            <div className="input floating">
              <FloatingLabelInput
                id="lastName"
                label={
                  <FormattedMessage
                    id="UserForm.LastName"
                    defaultMessage="Last name"
                  />
                }
                onChange={this.onLastNameChange}
                shrink={90}
                value={lastName}
              />
            </div>
            <span className="error">{lastNameMessage}</span>
            <div className="input floating">
              <FloatingLabelInput
                id="idNumber"
                label={
                  <FormattedMessage
                    id="UserForm.IdNumber"
                    defaultMessage="Id number"
                  />
                }
                onChange={this.onIdNumberChange}
                shrink={90}
                value={idNumber}
              />
            </div>
            <span className="error">{idNumberMessage}</span>
            <div className="terms">
              <input
                type="checkbox"
                id="termscheck"
                onClick={this.onCheckboxClick}
                checked={termsAccepted}
                disabled={processing}
              />
              <label htmlFor="termscheck" />
              <FormattedMessage
                id="UserForm.AcceptTnC"
                defaultMessage=" I Accept the "
              >
                {(msg) => (
                  <span>
                    <p onClick={this.onCheckboxClick}>{msg}</p>
                    <FormattedMessage
                      id="UserForm.TnC"
                      defaultMessage="terms and conditions"
                    >
                      {(terms) => (
                        <a
                          href={''}
                          onClick={this.toggle}
                          data-toggle="modal"
                          data-target="#termsmodal"
                        >
                          {terms}
                        </a>
                      )}
                    </FormattedMessage>
                  </span>
                )}
              </FormattedMessage>
            </div>

            <div hidden={!timerVisible || processFinished}>
              <Timer
                onRef={(ref) => {
                  this.timer = ref;
                }}
                setTimerVisible={this.setTimerVisible}
                setTimerFinished={this.setTimerFinished}
              />
            </div>
          </div>
          <div className="field submit user-form">
            <FormattedMessage id="UserForm.Submit" defaultMessage="Save">
              {(msg) => (
                <input
                  type="submit"
                  disabled={!isValidForm || processing}
                  onClick={this.submit}
                  value={msg}
                  className="btn btn-default"
                />
              )}
            </FormattedMessage>
            {processing && !processFinished ? (
              <ReactLoading
                className="submit loader"
                type="spin"
                color="black"
                height={30}
                width={30}
              />
            ) : (
              false
            )}
          </div>
        </form>

        <Portal node={document && document.getElementById('content')}>
          <div className="error" hidden={!errorMessage}>
            <p>{errorMessage}</p>
          </div>
        </Portal>

        <Portal>
          <Modal
            modalClassName="modalContent"
            size={processFinished ? 'sm' : 'lg'}
            isOpen={modal}
            toggle={this.toggle}
          >
            <ModalHeader toggle={this.toggle}>
              <div className="modalTitle">{modalTitle}</div>
            </ModalHeader>
            {processFinished ? (
              <ModalBody>
                <div className="modalBody">{modalBody}</div>
              </ModalBody>
            ) : (
              <div className="divWithScroll">
                <div className="divToScroll">
                  <ModalBody>
                    <div
                      className="termsModalBody"
                      dangerouslySetInnerHTML={{ __html: modalBody }}
                    />
                  </ModalBody>
                </div>
              </div>
            )}
            <ModalFooter className="modalFooter">
              <button
                type="button"
                className={
                  processFinished
                    ? 'btn btn-default modalButton'
                    : 'btn btn-default modalButton form'
                }
                onClick={this.toggle}
              >
                <FormattedMessage id="App.Ok" defaultMessage="Ok" />
              </button>
            </ModalFooter>
          </Modal>
        </Portal>
      </div>
    );
  }
}

UserForm.propTypes = {
  setProcessFinished: PropTypes.func.isRequired,
  setTimerFinished: PropTypes.func.isRequired,
  kashtag: PropTypes.string.isRequired,
};

export default UserForm;
