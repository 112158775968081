import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  W17, N14, MX05, M28, MX03,
} from '../../../../imgs';

const Slide = ({ Image, mainText, ContentText }) => (
  <div>
    <div className="text-center mb-5">
      <img
        src={Image}
        alt="send-request-money"
        className="img-fluid user-select-none"
      />
    </div>
    <h2 className="text-center mb-4 user-select-none">
      <strong>{mainText}</strong>
    </h2>
    {ContentText}
  </div>
);

const SlideA = index => (
  <Slide
    key={index}
    Image={W17}
    mainText={(
      <FormattedMessage
        id="Referrals.SlideAMainText"
        defaultMessage="Envíe y reciba dinero en tiempo real"
      />
    )}
    ContentText={(
      <p className="text-center text-body regular x1 user-select-none">
        <FormattedMessage
          id="Referrals.SlideAContentText"
          defaultMessage="Kash es su app para enviar y recibir dinero en tiempo real, entre distintos bancos del país y de toda Centroamérica."
        />
      </p>
    )}
  />
);

const SlideB = index => (
  <Slide
    key={index}
    Image={N14}
    mainText={(
      <FormattedMessage
        id="Referrals.SlideBMainText"
        defaultMessage="Respaldados por Visa y Mastercard"
      />
    )}
    ContentText={(
      <p className="text-center text-body regular x1 user-select-none">
        <FormattedMessage
          id="Referrals.SlideBContentText"
          defaultMessage="Su información siempre estará segura en Kash, ingrese con tranquilidad tu tarjeta de débito Visa y Mastercard."
        />
      </p>
    )}
  />
);

const SlideC = index => (
  <Slide
    key={index}
    Image={MX05}
    mainText={(
      <FormattedMessage
        id="Referrals.SlideCMainText"
        defaultMessage="Mueva su dinero desde donde usted quiera"
      />
    )}
    ContentText={(
      <p className="text-center text-body regular x1 user-select-none">
        <FormattedMessage
          id="Referrals.SlideCContentText"
          defaultMessage="Olvídese de las largas filas, con Kash transfiera entre usuarios del mismo país o a otros países de Centroamérica."
        />
      </p>
    )}
  />
);

const SlideD = index => (
  <Slide
    key={index}
    Image={M28}
    mainText={(
      <FormattedMessage
        id="Referrals.SlideDMainText"
        defaultMessage="Mueva su dinero desde donde usted quiera"
      />
    )}
    ContentText={(
      <p className="text-center text-body regular x1 user-select-none">
        <FormattedMessage
          id="Referrals.SlideDContentText"
          defaultMessage="Olvídese de las largas filas, con Kash transfiera entre usuarios del mismo país o a otros países de Centroamérica."
        />
      </p>
    )}
  />
);

const SlideE = index => (
  <Slide
    key={index}
    Image={MX03}
    mainText={(
      <FormattedMessage
        id="Referrals.SlideEMainText"
        defaultMessage="Súmase a la comunidad Kash"
      />
    )}
    ContentText={(
      <p className="text-center text-body regular x1 user-select-none">
        <FormattedMessage
          id="Referrals.SlideEContentText"
          defaultMessage="Nos da mucho gusto que este aquí. Cree una cuenta, solicite y transfiera dinero, invite o encuentre a sus amigos y súmase a la comunidad de Kash."
        />
      </p>
    )}
  />
);

export {
  SlideA, SlideB, SlideC, SlideD, SlideE,
};
