import React, { useEffect, useState } from 'react';
import './Swiper.scss';
import deviceDetector from '../../../../utils/deviceDetector';
import { KashLogotipo } from '../../../../imgs';

const Dot = ({ active }) => (
  <div className="dot">
    <div className={`fill ${active ? 'active' : ' inactive'}`} />
  </div>
);

const Swiper = ({
  activeSlide = 0,
  containerClassName,
  slides,
  onChangeActiveSlide,
}) => {
  const [isActiveMovement, setIsActiveMovement] = useState(false);
  const [mouseDownPosX, setMouseDownPosX] = useState(0);
  const [slideMovement, setSlideMovement] = useState(0);
  const [slideTranslationX, setSlideTranslationX] = useState(0);
  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);
  const { isMobile } = deviceDetector();

  useEffect(() => {
    runSlide(slideMovement);
  }, [slideMovement]);

  useEffect(() => {
    setSlideTranslationX(`${-100 * activeSlide}%`);
  }, [activeSlide]);

  const handleMouseMove = (evt) => {
    if (isActiveMovement) {
      const movement = mouseDownPosX - evt.clientX;

      setSlideMovement(movement);
    }
  };

  const runSlide = (movement) => {
    const isInValidRange = activeSlide < slides.length - 1;

    if (isInValidRange) {
      setSlideTranslationX(`calc(${-100 * activeSlide}% + ${-1 * movement}px)`);

      if (movement >= 60) {
        setIsActiveMovement(false);
        onChangeActiveSlide(activeSlide + 1);
      }
    }
  };

  const hanleMouseDown = (evt) => {
    if (!isMobile) {
      setMouseDownPosX(evt.clientX);
      setIsActiveMovement(true);
    }
  };
  const handleTouchStart = (evt) => {
    if (isMobile) {
      const firstTouch = evt.touches[0];
      setXDown(firstTouch.clientX);
      setYDown(firstTouch.clientY);
    }
  };

  const handleMouseUp = () => {
    if (!isMobile) {
      setIsActiveMovement(false);
      if (slideMovement < 60) {
        setSlideTranslationX(`${-100 * activeSlide}%`);
      }
    }
  };

  const handleTouchMove = (evt) => {
    if (isMobile) {
      if (!xDown || !yDown) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;

      const isRightSwipe = xDiff > 0;
      const isDownSwipe = yDiff > 0;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (isRightSwipe) {
          const isInValidRange = activeSlide < slides.length - 1;

          if (isInValidRange) {
            onChangeActiveSlide(activeSlide + 1);
          }
        }
      } else if (isDownSwipe) {
        /* down swipe */
      } else {
        /* up swipe */
      }

      setXDown(null);
      setYDown(null);
    }
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      className={`swiper ${containerClassName}`}
    >
      <div className={[`window ${activeSlide === slides.length - 1 && 'last-slide'}`]}>
        <div className="d-flex justify-content-center">
          <img src={KashLogotipo} alt="Logotipo" />
        </div>
        <div
          role="slider"
          aria-valuenow={activeSlide}
          tabIndex={-1}
          className="slides"
          onMouseDown={hanleMouseDown}
          onTouchStart={handleTouchStart}
          onMouseUp={handleMouseUp}
          onTouchMove={handleTouchMove}
          style={{ transform: `translateX(${slideTranslationX})` }}
        >
          {slides.map((item, index) => (
            <div key={index} className="slide">
              {item(index)}
            </div>
          ))}
        </div>
        <div className="dots">
          {slides.map((item, index) => (
            <Dot key={index} active={index === activeSlide} />
          ))}
        </div>
      </div>


    </div>
  );
};

export default Swiper;
