import React from 'react';
import './ButtonPrimary.scss';

const ButtonPrimary = ({ children, onClick = () => {}, isThereOnlyOneBth = false }) => (
  <button
    className={
      [`btn btn-primary text-uppercase ${isThereOnlyOneBth && 'one-btn'}`]
    }
    type="button"
    htmlFor="primary"
    onClick={onClick}
  >
    {children}
  </button>
);
export default ButtonPrimary;
