import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/home/home';
import Qr from '../pages/qr/qr';
import QrDynamic from '../pages/dynamic/dynamic';
import Pay from '../pages/pay/pay';
import OpenSupportKash from '../pages/bot/support';
import OpenWhatsappUserStatus from '../pages/bot/whtaspp-modify-status';
import Referred from '../pages/referred';

const MainRoutes = () => (
  <Routes>
    <Route path="/qr/:token" element={<Qr />} />
    <Route path="/d-qr/:token" element={<QrDynamic/>} />
    <Route path="/qr/pr" element={<QrDynamic />} />
    <Route path="/qr/pr/:token" element={<QrDynamic />} />
    <Route path="/pay" element={<Pay />} />
    <Route path="/pay/:token" element={<Pay/>} />
    <Route path="/support" element={<OpenSupportKash />} />
    <Route path="/support/:country" element={<OpenSupportKash />} />
    <Route path="/whatsapp-transfer" element={<OpenWhatsappUserStatus />} />
    <Route
      path="/whatsapp-transfer/:country"
      element={<OpenWhatsappUserStatus />}
    />
    <Route path="/referred/:code?" element={<Referred />} />
    <Route index element={<Home/>} />
  </Routes>
);

export default MainRoutes;
