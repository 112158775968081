import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {}, // remaining time
      seconds: 180, // starting time (3 minutes)
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    const timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.props.onRef(undefined);
  }

  /**
   * Transforms the initial time from seconds
   * to hours, minutes and seconds.
   * @param {number} secs seconds to transform
   */
  secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60));

    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  /**
   * Starts the timer countdown
   */
  startTimer() {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  /**
   * Counts down removing one second at a time.
   */
  countDown() {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds,
    });
    // Check if timer is at 1 minute or less
    if (seconds <= 60) {
      this.props.setTimerVisible(true);
    }
    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.props.setTimerFinished(true);
    }
  }

  /**
   * Adds a cero to the front of the number
   * if it is a one digit number.
   * @param {number} num number to format
   */
  format(num) {
    let formattedNum = `${num}`;
    while (formattedNum.length < 2) formattedNum = `0${formattedNum}`;
    return formattedNum;
  }

  render() {
    return (
      <span className="counter">
        {this.format(this.state.time.m)}
        :
        {this.format(this.state.time.s)}
      </span>
    );
  }
}

Timer.propTypes = {
  setTimerFinished: PropTypes.func.isRequired,
  setTimerVisible: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
};

export default Timer;
