let envVars;
switch (process.env.REACT_APP_STAGE) {
  case 'qa':
    envVars = {
      'ENV': 'qa',
      'REACT_APP_HIDE': process.env.REACT_APP_HIDE_QA,
      'NODE_ENV': process.env.NODE_ENV_QA,
      'REACT_APP_STAGE': process.env.REACT_APP_STAGE_QA,
      'PUBLIC_URL': process.env.PUBLIC_URL_QA,
      'URL_API': process.env.URL_API_QA,
      'API_KEY': process.env.API_KEY_QA,
      'URL_API_DYNAMIC': process.env.URL_API_DYNAMIC_QA,
      'DEFAULT_LANGUAGE': process.env.DEFAULT_LANGUAGE,
    };
    break;
  case 'dev':
    envVars = {
      'ENV': 'dev',
      'REACT_APP_HIDE': process.env.REACT_APP_HIDE_DEV,
      'NODE_ENV': process.env.NODE_ENV_DEV,
      'REACT_APP_STAGE': process.env.REACT_APP_STAGE_DEV,
      'PUBLIC_URL': process.env.PUBLIC_URL_DEV,
      'URL_API': process.env.REACT_APP_URL_API_DEV,
      'API_KEY': process.env.REACT_APP_API_KEY_DEV,
      'URL_API_DYNAMIC': process.env.REACT_APP_URL_API_DYNAMIC_DEV,
      'DEFAULT_LANGUAGE': process.env.REACT_APP_DEFAULT_LANGUAGE,
    };
    break;
  case 'stage':
    envVars = {
      'ENV': 'stage',
      'REACT_APP_HIDE': process.env.REACT_APP_HIDE_STAGE,
      'NODE_ENV': process.env.NODE_ENV_STAGE,
      'REACT_APP_STAGE': process.env.REACT_APP_STAGE_STAGE,
      'PUBLIC_URL': process.env.PUBLIC_URL_STAGE,
      'URL_API': process.env.URL_API_STAGE,
      'API_KEY': process.env.API_KEY_STAGE,
      'URL_API_DYNAMIC': process.env.URL_API_DYNAMIC_STAGE,
      'DEFAULT_LANGUAGE': process.env.DEFAULT_LANGUAGE,
    };
    break;
  case 'test':
    envVars = {
      'ENV': 'test',
      'REACT_APP_HIDE': process.env.REACT_APP_HIDE_TEST,
      'NODE_ENV': process.env.NODE_ENV_TEST,
      'REACT_APP_STAGE': process.env.REACT_APP_STAGE_TEST,
      'PUBLIC_URL': process.env.PUBLIC_URL_TEST,
      'URL_API': process.env.URL_API_TEST,
      'API_KEY': process.env.API_KEY_TEST,
      'URL_API_DYNAMIC': process.env.URL_API_DYNAMIC_TEST,
    };
    break;
  case 'prod':
    envVars = {
      'ENV': 'prod',
      'REACT_APP_HIDE': process.env.REACT_APP_HIDE_PROD,
      'NODE_ENV': process.env.NODE_ENV_PROD,
      'REACT_APP_STAGE': process.env.REACT_APP_STAGE_PROD,
      'PUBLIC_URL': process.env.PUBLIC_URL_PROD,
      'URL_API': process.env.URL_API_PROD,
      'API_KEY': process.env.API_KEY_PROD,
      'URL_API_DYNAMIC': process.env.URL_API_DYNAMIC_PROD,
      'DEFAULT_LANGUAGE': process.env.DEFAULT_LANGUAGE,
    };
    break;
  default:
    envVars = {
      'ENV': 'prod',
      'REACT_APP_HIDE': process.env.REACT_APP_HIDE_PROD,
      'NODE_ENV': process.env.NODE_ENV_PROD,
      'REACT_APP_STAGE': process.env.REACT_APP_STAGE_PROD,
      'PUBLIC_URL': process.env.PUBLIC_URL_PROD,
      'URL_API': process.env.URL_API_PROD,
      'API_KEY': process.env.API_KEY_PROD,
      'URL_API_DYNAMIC': process.env.URL_API_DYNAMIC_PROD,
      'DEFAULT_LANGUAGE': process.env.DEFAULT_LANGUAGE,
    };
    break;
}

export default envVars;