import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserView, MobileView, isAndroid } from 'react-device-detect';
import '../../css/style.css';
import '../../css/media.css';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import ReactLoading from 'react-loading';
import querySearch from 'stringquery';
import Footer from '../../Components/footer/footer';
import envVars from '../../Data/envs';
const LOGO = 'https://dev.kashtag.me/b292fa55bb54e58b1a32506b60b2e1e1.png';

const PayComponent = () => {
  const location = useLocation();

  const [deepLink, setDeepLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { requestId } = querySearch(location.search);
        const deepLink = `kash://app/pay/${requestId}`;
        openApp(deepLink);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  });

  /**
   *
   * @param {object} data - return link deep that open the app
   */
  const openApp = (data) => {
    if (data) {
      window.location.replace(data);
      setDeepLink(data);
    } else {
      setModal(true);
    }
  };

  const renderLoading = () => (
    <ReactLoading type="spin" color="#505050" height={100} width={100} />
  );

  const renderModal = () => (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(false)}>
        ¡Escanea un codigo córrectosss!
      </ModalHeader>
      <ModalBody>
        Aseguráte que estás enviando o solicitando dinero de la personass
        correcta
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setModal(false)}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );

  const urlStore = isAndroid
    ? 'https://play.google.com/store/apps/details?id=com.namutek.kash'
    : 'https://apps.apple.com/app/kash/id1476122795';

  return (
    <div>
      {renderModal()}
      <div className="body">
        <header>
          <img
            src={LOGO}
            alt="Logo"
            className={
              envVars.REACT_APP_HIDE === 'true'
                ? 'img-fluid full'
                : 'img-fluid'
            }
          />

          {envVars.REACT_APP_HIDE === 'true' ? (
            false
          ) : (
            <div id="content" className="content content-qr">
              {loading ? (
                <div className="content-loading">{renderLoading()}</div>
              ) : (
                <div className="content-box-qr">
                  {deepLink && <p className="text-go">Abrir en Kash</p>}
                  <BrowserView>
                    <a href="https://kashlatam.com/" className="button-go">
                      Descargar
                    </a>
                  </BrowserView>
                  <MobileView>
                    {deepLink && (
                      <button
                        className="button-go"
                        onClick={() => openApp(deepLink)}
                      >
                        Abrir
                      </button>
                    )}
                  </MobileView>
                  <MobileView>
                    <hr />
                    <p>¿Aún no tienes Kash?</p>
                    <a href={urlStore}>Descargar</a>
                  </MobileView>
                </div>
              )}
            </div>
          )}
        </header>
        <Footer />
      </div>
    </div>
  );
};

export default PayComponent;
