import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import envVars from '../Data/envs';
let userPool = null; // cognito's user pool to connect to
const defaultPassword = 'Passw0rd!'; // default password for all the users
let cognitoUser = null; // user's cognito information

/**
 * Sets the user pool with the user pool data
 */
function setUserPool() {
  if (userPool === null) {
    const userPoolId = (envVars.REACT_APP_STAGE === 'prod') ? 'us-east-2_T8cm4WoDZ' : 'us-east-2_RKxy4wlxK';
    const clientId = (envVars.REACT_APP_STAGE === 'prod') ? '6g09khf4jm0q3ilan4gv4epfkf' : '3p1198j3hbinp3o3oiosjl7atr';
    const poolData = { // Data to connect to the user pool in cognito
      UserPoolId: userPoolId,
      ClientId: clientId,
    };
    userPool = new CognitoUserPool(poolData);
  }
}

/**
 * Returns the user pool
 */
export const getUserPool = () => {
  setUserPool();
  return userPool;
};

/**
 * Sets the cognito user to be used insede the cognito provider
 * @param {string} username
 */
const setCognitoUser = (username) => {
  if (!cognitoUser || username !== '') {
    const userData = {
      Username: username,
      Pool: getUserPool(),
    };
    cognitoUser = new CognitoUser(userData);
  }
};

/**
 * Signs up the user in cognito
 * @param {string} phoneNumber user's phone number
 * @param {object} validationDataList data that is going to be used by a lambda after a signUp trigger
 * @param {function} action function that handles what the signUp returns
 */
export const signUp = (phoneNumber, validationDataList, action) => {
  setUserPool();
  return userPool.signUp(phoneNumber, defaultPassword, null, validationDataList, (err, result) => {
    if (!err) {
      cognitoUser = result.user;
    }
    action(err, result);
  });
};

/**
 * Confirms the user registration, setting him to confirmed in cognito
 * @param {string} code code entered by the user (received via SMS)
 * @param {function} action function that handles what the confirmRegistration returns
 */
export const confirmRegistration = (code, action) => cognitoUser.confirmRegistration(code, true, (err, result) => {
  action(err, result);
});

/**
 * Resends the verification code via SMS to the user
 * @param {string} username username of the current user
 * @param {function} action function that handles what the confirmRegistration returns
 */
export const resendConfirmationCode = (username, action) => {
  setCognitoUser(username);
  return cognitoUser.resendConfirmationCode((err, result) => {
    action(err, result);
  });
};

/**
 * Authenticates the user in cognito
 * @param {string} username username of the current user
 * @param {object} object functions that handle what the authentication returns, in case it is successfull or not
 */
export const authenticateUser = (username = '', { onSuccess, onFailure, mfaRequired }) => {
  setCognitoUser(username);
  const authenticationData = {
    Username: cognitoUser.getUsername(),
    Password: defaultPassword,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  return cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onFailure: (err) => {
      onFailure(err);
    },
    mfaRequired: (result) => {
      mfaRequired(result);
    },
  });
};

/**
 * Enables the MFA to send the verification code
 * by SMS everytime the user signs in
 * @param {function} action function that handles what the enableMFA returns
 */
export const enableMFA = action => cognitoUser.enableMFA((err, result) => {
  action(err, result);
});

/**
 * Sends to cognito the code entered by the user to be verified
 * @param {string} code code to send to cognito to verify
 * @param {object} object functions that handle what the authentication returns, in case it is successfull or not
 */
export const sendMFACode = (code, { onSuccess, onFailure }) => {
  cognitoUser.sendMFACode(code, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onFailure: (err) => {
      onFailure(err);
    },
  });
};

/**
 * Signs out the cognito user
 */
export const signOut = () => {
  userPool.getCurrentUser().signOut();
};
